import Button from "../button";

import { Container } from "./styles";

import img1 from "../../assets/img/fecha-preto.svg";

export default function ModalGrandCru({
    isOpen,
    clickContinue,
    handleClose,
    cor,
}) {
    return (
        <Container data-show={isOpen} cor={cor}>
            <div className="content modalGrandCru">
                <button className="close-modal" onClick={() => handleClose(false)}>
                    <img src={img1} alt="" width={20} height={20} />
                </button>
                <div className="titulo">
                    <h2>Importante você saber</h2>
                </div>
                <div className="mensagem">
                    <span>
                        O iFood está descontinuando a comercialização do produto "iFood Card". Por isso, o benefício de crédito do Minhas Vantagens estará disponível enquanto durarem os estoques. Continue com o resgate para garantir o seu benefício.
                    </span>
                </div>
                <Button
                    title="Continuar para resgate"
                    tipo="branco"
                    onClick={clickContinue}
                />
                <p
                    className="depois"
                    onClick={() => {
                        handleClose(false);
                    }}
                >
                    Voltar
                </p>
            </div>
        </Container>
    );
}
