import Button from "../button";

import img from "../../assets/img/icon-mensagem.svg";

import { Container } from "./styles";

export default function ModalSucesso({ isOpen, handleClose, cor, ticket }) {
  return (
    <Container data-show={isOpen} cor={cor} className="avisoSucesso">
      <div className="content">
        <div className="img-titulo">
          <img src={img} alt="" />
        </div>
        <div className="body">
          <div className="titulo">
            <h2>Agradecemos seu contato!</h2>
            <span>
              Em até 6 horas úteis (entre 9h e 18h de segunda a sexta-feira)
              você receberá uma resposta da Equipe Yetz no seu e-mail utilizado
              para nos contatar.
              <br />
              <br />
              Mas ATENÇÃO, não deixe de conferir seu SPAM ou LIXO ELETRÔNICO
              caso passe desse período e não o localize em sua Caixa de Entrada.
            </span>
            {/* <div className="titulo">
              <span className="span-ticket">Este é seu número de ticket</span>
              <p className="parag-ticket">{ticket ? ticket : ""}</p>
            </div> */}
          </div>
          <Button
            tipo="roxo"
            title="OK, ENTENDI"
            onClick={() => handleClose(!isOpen)}
          />
        </div>
      </div>
    </Container>
  );
}
