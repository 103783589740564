import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { api } from "../../services/api";

import { dataURLtoFile } from "../../services/helper";

import InputFile from "../../components/inputFile";
import Button from "../../components/button";
import ModalSucesso from "./sucesso";
import ModalErro from "./erro";

import { Container } from "./styles";

import img from "../../assets/img/fechar.svg";

export default function ModalFaleConosco({
  isOpen,
  handleClose,
  acao,
  link_acao,
  login = false,
  cor,
  label_login,
  lgpd_cpf_truncado,
  action_id,
}) {
  const [mensagem, setMensagem] = useState();
  const [activeSucesso, setActiveSucesso] = useState(false);
  const [activeErro, setActiveErro] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [loadingMsg, setLoadingMsg] = useState(false);

  const defaultValues = {
    nome: "",
    email: "",
    celular: "",
    login: "",
    mensagem: "",
    totalSoma: "",
    arquivo: "",
    nomeArquivo: "",
  };
  const validationSchema = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    email: Yup.string().required("Campo Obrigatório"),
    celular: Yup.string().required("Campo Obrigatório"),
    mensagem: Yup.string().required("Campo Obrigatório"),
    totalSoma: Yup.number().required("Campo Obrigatório"),
  });

  const {
    formState: { errors },
    register,
    ...form
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    var valor1 = Math.floor(Math.random() * 10 + 1);
    var valor2 = Math.floor(Math.random() * 10 + 1);

    document.getElementById("valor1").innerHTML = valor1;
    document.getElementById("valor2").innerHTML = valor2;
  }, []);

  const handleSubmit = form.handleSubmit((values) => {
    setLoadingMsg(true);

    const valor1 = parseInt(document.getElementById("valor1").innerHTML);
    const valor2 = parseInt(document.getElementById("valor2").innerHTML);

    var nome = values.nome.split(" ");
    if (nome.length <= 1) {
      setLoadingMsg(false);
      setMensagem("Preencha o nome completo");
      setActiveErro(true);
      return;
    } else if (
      lgpd_cpf_truncado &&
      values.login.replace(/-|_/g, "").length !== 13
    ) {
      setLoadingMsg(false);
      setMensagem("O CPF não foi preenchido corretamente");
      setActiveErro(true);
      return;
    } else if (values.celular.replace(/-|_/g, "").length !== 14) {
      setLoadingMsg(false);
      setMensagem("O celular não foi preenchido corretamente");
      setActiveErro(true);
      return;
    } else {
      if (valor1 + valor2 === values.totalSoma) {
        const headers = {
          "Content-Type": "multipart/form-data",
        };
        const formData = new FormData();
        const img = values.arquivo
          ? dataURLtoFile(values.arquivo, values.nomeArquivo)
          : null;

        formData.append("action_id", action_id);
        formData.append("email", values.email);
        formData.append("name", values.nome);
        formData.append("mobile", values.celular);
        formData.append(
          "login",
          login ? login.replace(/\D/g, "") : values.login.replace(/\D/g, "")
        );
        formData.append("message", values.mensagem);
        formData.append("action_name", acao);
        formData.append("link", "https://yetzpromo.com.br/" + link_acao);
        formData.append("status", 1);
        formData.append("subject", "Promo | Fale Conosco");
        if (img) {
          formData.append("file", img);
        }
        api
          .post(`/movidesk/ticket`, formData, headers)
          .then((data) => {
            setTicket(data.ticket);
            setLoadingMsg(false);
            form.reset();
            handleClose(false);
            setActiveSucesso(true);
          })
          .catch(({ error }) => {
            setLoadingMsg(false);
            console.log("error ", error);
            form.reset();
            if (error) {
              setMensagem(error);
            } else {
              setMensagem("Algo deu errado, por favor tente mais tarde!");
            }
            setActiveErro(true);
          });
      } else {
        setLoadingMsg(false);
        setMensagem("reCAPTCHA inválido");
        setActiveErro(true);
      }
    }
  });

  return (
    <>
      <Container data-show={isOpen} cor={cor} className="faleConosco">
        <div className="content" style={{ overflowY: "auto" }}>
          <div className="topo-titulo">
            <div className="img-titulo">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.000244141"
                  y="0.393738"
                  width="44.6063"
                  height="44.6063"
                  rx="22.3031"
                  fill="white"
                />
                <path
                  d="M22.3169 37.38C30.4187 37.38 36.9865 30.8122 36.9865 22.7103C36.9865 14.6085 30.4187 8.04071 22.3169 8.04071C14.215 8.04071 7.64722 14.6085 7.64722 22.7103C7.64722 30.8122 14.215 37.38 22.3169 37.38Z"
                  stroke={cor}
                  strokeWidth="2.1822"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0582 26.0369C21.0582 24.9832 21.1913 24.1434 21.4574 23.5175C21.7235 22.8916 22.2476 22.2063 23.0298 21.4616C23.82 20.7089 24.32 20.1741 24.5296 19.8572C24.8522 19.3739 25.0134 18.851 25.0134 18.2885C25.0134 17.5438 24.8239 16.9773 24.4449 16.5891C24.074 16.1929 23.5257 15.9949 22.8 15.9949C22.1065 15.9949 21.5461 16.189 21.1187 16.5772C20.6994 16.9575 20.4897 17.4764 20.4897 18.134H17.5505C17.5667 16.7317 18.0505 15.6225 19.002 14.8065C19.9616 13.9904 21.2276 13.5824 22.8 13.5824C24.4208 13.5824 25.6827 13.9865 26.5858 14.7946C27.497 15.6027 27.9526 16.7317 27.9526 18.1815C27.9526 19.473 27.3398 20.7446 26.1141 21.9963L24.6264 23.4343C24.0942 24.0285 23.82 24.8961 23.8039 26.0369H21.0582ZM20.8526 29.7329C20.8526 29.2655 21.0018 28.8891 21.3001 28.6039C21.5985 28.3108 22.0017 28.1642 22.5097 28.1642C23.0257 28.1642 23.433 28.3147 23.7313 28.6158C24.0297 28.9089 24.1788 29.2813 24.1788 29.7329C24.1788 30.1687 24.0337 30.5331 23.7434 30.8262C23.4531 31.1194 23.0419 31.266 22.5097 31.266C21.9775 31.266 21.5662 31.1194 21.2759 30.8262C20.9937 30.5331 20.8526 30.1687 20.8526 29.7329Z"
                  fill={cor}
                />
              </svg>

              <h3>FALE CONOSCO</h3>
            </div>

            <img
              src={img}
              alt=""
              width={20}
              height={20}
              onClick={() => handleClose(false)}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="container-form">
              <input
                {...register("nome")}
                placeholder="DIGITE SEU NOME COMPLETO"
                type="text"
              />
              {errors.nome && <span>{errors.nome.message}</span>}
            </div>
            <div className="container-form">
              <input
                {...register("email")}
                placeholder="DIGITE SEU EMAIL"
                type="email"
              />
              {errors.email && <span>{errors.email.message}</span>}
            </div>
            <div className="container-form">
              <InputMask
                {...register("celular")}
                placeholder="DIGITE SEU CELULAR"
                type="text"
                mask="(99) 99999-9999"
              />
            </div>
            {!login && (
              <div className="container-form">
                {lgpd_cpf_truncado === 1 ? (
                  <InputMask
                    {...register("login")}
                    placeholder={label_login ?? "DIGITE SEU LOGIN"}
                    type="tel"
                    mask="999.999.999-99"
                  />
                ) : (
                  <input
                    {...register("login")}
                    placeholder={label_login ?? "DIGITE SEU LOGIN"}
                    type="text"
                  />
                )}
                {errors.login && <span>{errors.login.message}</span>}
              </div>
            )}

            <div className="container-form">
              <textarea
                {...register("mensagem")}
                placeholder="MENSAGEM"
                type="text"
              />
              {errors.mensagem && <span>{errors.mensagem.message}</span>}
            </div>

            <InputFile form={form} />

            <div className="container-form math">
              <p id="valor1"></p>
              <p> + </p>
              <p id="valor2"></p>
              <p> =</p>
              <input
                {...register("totalSoma")}
                placeholder="reCAPTCHA"
                type="text"
              />
              {errors.totalSoma && <span>{errors.totalSoma.message}</span>}
            </div>
            <Button
              title="ENVIAR"
              type="submit"
              loading={loadingMsg}
              disabled={loadingMsg}
            />

            <span className="msg-footer">
              Em até 6 horas úteis (entre 9h e 18h de segunda a sexta-feira)
              você receberá uma resposta da Equipe Yetz no seu e-mail utilizado
              para nos contatar.
              <br />
              <br />
              Mas ATENÇÃO, não deixe de conferir seu SPAM ou LIXO ELETRÔNICO
              caso passe desse período e não o localize em sua Caixa de Entrada.
            </span>
          </form>
        </div>
      </Container>
      <ModalSucesso
        isOpen={activeSucesso}
        handleClose={() => setActiveSucesso(false)}
        cor={cor}
        ticket={ticket}
      />
      <ModalErro
        isOpen={activeErro}
        mensagem={mensagem}
        handleClose={() => setActiveErro(false)}
        cor={cor}
      />
    </>
  );
}
