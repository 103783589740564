import { useLocation } from "react-router-dom";
import { Container } from "./styles";
import { useAcaoContext } from "../../contexts/acaoContext";
import imgManutencao from "../../assets/img/manutencao.svg";
import imgManutencaoStone from "../../assets/img/manutencao_stone.png";
import LogoTon from "../../assets/img/logo_ton_verde.svg";
import LogoItau from "../../assets/img/LogoItau.svg";
import LogoItauComPersonnalite from "../../assets/img/LogoItauComPersonnalite.svg";
import fundo from "../../assets/img/fundo_natal.png";
import natal from "../../assets/img/natal.png";
import Footer from "../../components/footer";

export function Manutencao() {
  const width = window.screen.width;

  const location = useLocation();
  const isPersonnalite =
    location.pathname.includes("personnalite") ||
    location.pathname.includes("personnalite3");
  const LogoItauOuComPersonnalite = isPersonnalite
    ? LogoItauComPersonnalite
    : LogoItau;

  const { configuracao, isStone } = useAcaoContext();

  const isItau =
    configuracao?.key.includes("personnalite") ||
    configuracao?.key.includes("uniclass");

  return (
    <>
      {isStone ? (
        <Container background={configuracao?.background || ""}>
          <div className="content stone">
            <div className="container-img">
              <img src={imgManutencaoStone} alt="" />
            </div>

            {configuracao?.blocked_text ? (
              <div className="container-text">
                <div
                  className="container-html"
                  dangerouslySetInnerHTML={{
                    __html: configuracao?.blocked_text,
                  }}
                ></div>
                <img className="logo" src={LogoTon} alt="" />
              </div>
            ) : (
              <div className="container-text">
                <h3>Olá!</h3>
                <h5>
                  Estamos em atualização da nossa plataforma para deixar sua
                  experiência ainda melhor!
                </h5>{" "}
                <p>
                  Retorne em algumas horas para escolher o benefício de sua
                  preferência.
                </p>
                <img src={LogoTon} alt="" />
              </div>
            )}
          </div>
        </Container>
      ) : (
        <Container background={configuracao?.background || ""}>
          <div className="content">
            <div className={`container-img ${isItau ? "natal" : ""}`}>
              <img src={isItau ? natal : imgManutencao} alt="" />
            </div>

            {configuracao?.blocked_text ? (
              <div
                className="container-html"
                dangerouslySetInnerHTML={{
                  __html: configuracao?.blocked_text,
                }}
              ></div>
            ) : (
              <div className="container-text">
                <h3>Olá!</h3>
                <p>
                  Estamos em atualização da nossa plataforma para deixar sua
                  experiência {width < 480 ? <br /> : <></>} ainda melhor!
                </p>
                <p>
                  Retorne em algumas horas para escolher o benefício de sua
                  preferência.
                </p>
              </div>
            )}
          </div>
          <div className="logo">
            <img src={LogoItauOuComPersonnalite} alt="logo" />
          </div>
        </Container>
      )}

      {width >= 480 ? <Footer /> : null}
    </>
  );
}
